import { InternalLink } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Alert } from "@mui/material";
import type { Agent } from "@src/appV2/Agents";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useFetchPaginatedShiftBlocks } from "@src/appV2/ShiftBlocks/api/useFetchPaginatedShiftBlocks";
import { ShiftBookingType } from "@src/appV2/ShiftBlocks/utils";
import {
  useStreaksEnabledForHcp,
  useStreaksEnabledGlobally,
} from "@src/appV2/Streaks/api/featureFlagHooks";
import { useGetAgentActiveStreaks } from "@src/appV2/Streaks/api/useGetAgentActiveStreaks";
import { startOfWeek } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { isEmpty } from "lodash";

export const DEFAULT_STREAKS_TIMEZONE = "America/Los_Angeles";

interface ShiftBlockStreakAlertWrapperProps {
  agent: Agent;
}

export function ShiftBlockStreakAlertWrapper(props: ShiftBlockStreakAlertWrapperProps) {
  const { agent } = props;

  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();
  const isStreaksEnabledForHcp = useStreaksEnabledForHcp();
  const qualification = agent?.preference?.qualification ?? agent?.licensesData[0].qualification;
  const { data: shiftBlocksData, hasNextPage } = useFetchPaginatedShiftBlocks(
    {
      filter: {
        agentId: agent.userId,
        qualification,
      },
    },
    {
      enabled: isDefined(qualification),
    }
  );
  const { data: activeStreaksResponse } = useGetAgentActiveStreaks(
    {
      agentId: agent.userId,
      filter: {
        date: zonedTimeToUtc(
          startOfWeek(utcToZonedTime(new Date(), DEFAULT_STREAKS_TIMEZONE), {
            weekStartsOn: 0,
          }),
          DEFAULT_STREAKS_TIMEZONE
        ),
      },
    },
    { enabled: !isEmpty(agent.userId) && isStreaksEnabledGlobally }
  );

  const hasAnyActiveStreaks = (activeStreaksResponse?.data?.length ?? 0) > 0;
  const shiftBlockCount = shiftBlocksData?.pages[0]?.data.length ?? 0;
  const shiftBlocksMessage = `View ${
    hasNextPage ? "over" : ""
  } ${shiftBlockCount} open blocks here.`;
  const showAlert =
    !hasAnyActiveStreaks &&
    isStreaksEnabledGlobally &&
    isStreaksEnabledForHcp &&
    shiftBlockCount > 0;

  return showAlert ? (
    <Alert
      severity="info"
      sx={{
        margin: "10px",
        padding: "0 5px",
        borderRadius: 0,
        display: "flex",
        alignItems: "center",
      }}
    >
      Blocks are a great way to lock in Streaks!{" "}
      <InternalLink
        to={{
          pathname: DeprecatedGlobalAppV1Paths.OPEN_SHIFTS,
          search: `?shiftBookingTab=${ShiftBookingType.BLOCK_BOOKING}`,
        }}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.VIEWED_OPEN_BLOCKS_FROM_STREAK_TRACKER, {
            agentId: agent.userId,
            shiftBlockCount,
            hasNextPage,
          });
        }}
      >
        {shiftBlocksMessage}
      </InternalLink>
    </Alert>
  ) : null;
}
